<template lang="pug">
include ../../../configs/template
div.container-fluid
  div.row
    div.col-12
      Rating(
        v-if="isShowSetRating"
        :visible="isShowSetRating"
        :statementId="paketStatementById.id"
        :viewEditOnly="true"
        :readonly="true"
        :callBack="() => getPacketStatementById({ ...$route.params })"
        )
  div(v-if="!this.paketStatementById.is_payed && paketStatementById.rank").row
    +select('body.include_sailor_passport', 'ListProcessingOptions', 'model-SailorPassport', 'nameLang').col-12
  div.row
    +select('body.is_payed', 'ListPaymentStatus', 'payment', 'nameLang')(item-value="status").col-12
  div.row
    div.col-12
      v-btn(color="success" @click="saveEditPaket" :loading="isLaoding") {{ $t('save') }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { STATEMENT_POSITION_SAILOR, SUCCESS_CODE } from '@/configs/constants'
// import { checkAccess } from '@/mixins/permissions'
import { clearBody } from '@/mixins/main'

const initBody = () => ({
  include_sailor_passport: null,
  is_payed: false
})

export default {
  name: 'PaketPositionEdit',
  components: {
    Rating: () => import('@/components/atoms/Rating.vue')
  },
  props: {
    paketStatementById: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: initBody(),
      isShowSetRating: false,
      isLaoding: false
    }
  },
  computed: {
    ...mapState({
      rating: state => state.sailor.rating,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      ListPaymentStatus: state => state.directory.paymentStatus,
      ListProcessingOptions: state => state.sailor.sailorPassportProcessing.filter(item => STATEMENT_POSITION_SAILOR.includes(item.id))

    })
  },
  mounted () {
    Object.keys(this.body).forEach((key) => (this.body[key] = this.paketStatementById[key]))
  },
  methods: {
    ...mapActions(['getPacketStatementById', 'updatePaketStatement']),
    async saveEditPaket () {
      this.isLaoding = true
      const response = await this.updatePaketStatement({ ...this.$route.params, body: { ...clearBody({ ...this.body }) } })
      if (SUCCESS_CODE.includes(response.code)) {
        this.body = initBody()
        // if (checkAccess('positionStatement', 'changeRating', response.data)) this.isShowSetRating = true
        // else await this.getPacketStatementById({ ...this.$route.params })
        await this.getPacketStatementById({ ...this.$route.params })
      }
      this.isLaoding = false
    }
  }
}
</script>
